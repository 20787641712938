import { Injectable, inject } from '@angular/core';

import { BooktechAppService, HookType } from '@btlib-core';

export const APP_DATAID = {

}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  name = "AppService"

  

  public bas = inject(BooktechAppService);

  constructor() {
    if(this.bas.envtest) console.log("AppService: ", this.bas.settings?.appId);
    
    this.bas.hs.on(
      HookType.WebApiRequest, 
      (params) => this.onWebApiRequest(params),
      50
    );


    // let qp =  this.route.snapshot.queryParams;

    // this.bas.hs.trigger(HookType.WebApiRequest, {
    //   queryParams: qp,
  
    // })

   }


  onWebApiRequest(params:any) {
    if (this.bas.envtest) console.log("AppService.onWebApiRequest: ", params);
    return new Promise<boolean>((res, rej) => {
     

     
      let qp = params.queryParams || { };

      let page = decodeURIComponent( qp["cbapi-page"] );
      let action = qp["cbapi-action"];
      let toolsAction = qp["cbapi-tools-action"];


      let target = "";
      let queryParams:any = { };

      for (let pn in qp) {
        if (!pn.startsWith("cbapi-option-")) continue;
        let name = pn.substring("cbapi-option-".length);
        queryParams[name] = qp[pn];
      }


      

      let basePath = this.bas.ui.getRouterPrefix();

      if (page) {

        target = basePath + "/" + page;

        
        if (page == "index") {
          target = "/";
        } else if (page == "order/payment") {
          // https://192.168.1.84:8201/api/hemsedalaktiv/lib/api?cbapi-page=order%2Fpayment&cbapi-option-showCustomAmount=true&cbapi-option-id=AGM711
          target = basePath + "/common/order/payment";

        }
        else if (page == "confirmUser") {

          target = basePath + "/lib/user/confirm";

          queryParams = {
            action: toolsAction
          }
        }

        
      }

      if (action) {
        if (action == "showNewPasswordDialog") {
          // TODO:test
          target = basePath + "/lib/user/password";


          return;
        }

      }


      if (this.bas.envtest || !target) console.log("target: " + target + ", queryParams: ", queryParams);
      
      if (target) {
          this.bas.ui.router.navigate(
            [ target ], 
            { queryParams: queryParams }
          );
          res(false);
      }

      res(false);
      

    });
  }

}
